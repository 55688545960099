import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="Levítico" />
      <h1>Tipos y Sombras En El Antiguo Testamento </h1>
      <p>Abajo están las clases transcritas.</p>
      <h2>Tipos y Sombras en Levítico</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>
              <div align="center">01</div>
            </td>
            <td>Introducción a Levítico parte 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/058TS_IntroduccionALevitico.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">02</div>
            </td>
            <td>Introducción a Levítico parte 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/059TS_IntroduccionALevitico02.pdf')}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">03</div>
            </td>
            <td>El Holocausto</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/060TS_Holocausto.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">04</div>
            </td>
            <td>Las Cinco Ofrendas</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/061TS_CincoOfrendas.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">05</div>
            </td>
            <td>La Unción</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/062TS_Uncion.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">06</div>
            </td>
            <td>Levítico 9: El Deseo de Dios</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/063TS_DeseoDeDios.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">07</div>
            </td>
            <td>Levítico 10: Los Dos Hijos de Aarón</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/064TS_DosHijosDeAaron.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">08</div>
            </td>
            <td>Inmundo Y Limpio parte 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/065TS_InmundoLimpio01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">09</div>
            </td>
            <td>Inmundo Y Limpio parte 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/066TS_InmundoLimpio02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">10</div>
            </td>
            <td>Inmundo Y Limpio parte 3</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/067TS_InmundoLimpio03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">11</div>
            </td>
            <td>La Purificación </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/068TS_Purificacion.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">12</div>
            </td>
            <td>Las Fiestas parte 1 - Inroducción</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/069TS_Fiestas01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">13</div>
            </td>
            <td>Las Fiestas parte 2 - Pascua</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/070TS_Fiestas02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">14</div>
            </td>
            <td>Las Fiestas parte 3 - Pan Sin Levadura</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/071TS_Fiestas03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">15</div>
            </td>
            <td>Las Fiestas parte 4 - Primicias</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/072TS_Fiestas04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">16</div>
            </td>
            <td>Las Fiestas parte 5 - Pentecostés o Semanas</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/073TS_Fiestas05.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">17</div>
            </td>
            <td>Las Fiestas parte 6 - Trompetas</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/074TS_Fiestas06.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">18</div>
            </td>
            <td>Las Fiestas parte 7 - Día de Expiación</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/075TS_Fiestas07.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">19</div>
            </td>
            <td>Las Fiestas parte 8 - Tabernáculos</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/076TS_Fiestas08.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">20</div>
            </td>
            <td>Levítico 17 - La Sangre</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/077TS_La_Sangre.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">21</div>
            </td>
            <td>Levítico 18 - La Condición Natural de la Tierra</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/078TS_Condicion_Natural_de_Tierra.pdf')}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">22</div>
            </td>
            <td>Levítico 19 - Amarás a Tu Prójimo</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/079TS_Amaras_a_tu_Projimo.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">23</div>
            </td>
            <td>Levítico 21-24</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/080TS_Levitico_21-24.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="29">
              <div align="center">24</div>
            </td>
            <td width="434">Levítico 26 - Las Fronteras del Pacto</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/081TS_Levitico_26.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
